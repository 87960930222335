import React from 'react';
import { Modal } from 'react-bootstrap';


class MainSlider extends React.Component {

  constructor(props){
      super(props);
      this.state = {
          show: false,
          modal: false
      }
  }

  handleShow(){
      this.setState({show: true})
      window.dotq = window.dotq || [];
      window.dotq.push({
          'projectId': '10000',
          'properties': {
              'pixelId': '10176003',
              'userEmail': '<email_address>',
              'qstrings': {
                  'et': 'custom',
                  'ec':'token'
              }
          } 
      });
      console.log(window.dotq)
  }

  fireMintButton(){
      window.dotq = window.dotq || [];
      window.dotq.push({
          'projectId': '10000',
          'properties': {
              'pixelId': '10176003',
              'userEmail': '<email_address>',
              'qstrings': {
                  'et': 'custom',
                  'ec':'mint'
              }
          }
      });

      console.log(window.dotq)
  }

  openModal(){
      this.setState({modal:true})
      window.dotq = window.dotq || [];
      window.dotq.push({
          'projectId': '10000',
          'properties': {
              'pixelId': '10176003',
              'userEmail': '<email_address>',
              'qstrings': {
                  'et': 'custom',
                  'ec':'discord'
              }
          } 
      });
      console.log(window.dotq)
  }
render() {

  return (
	<div id="banner" className="slider-active">
        <div className="single-slider">
          <div className="banner-area banner-bg">
           
          <video className="bannerVideo" autoPlay muted loop poster="assets/img/banner/hero.webp">
              <source src="assets/img/banner/cab1.mp4" type="video/mp4" />
            </video>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner-content text-center">
                    <h4 className="sub-title" data-animation="fadeInUp" data-delay=".2s" data-duration="2s">NFT Mint DApp</h4>
                    <h2 className="title" data-animation="fadeInUp" data-delay=".4s" data-duration="2s">Trucking Empire</h2>
                    <p data-animation="fadeInUp" data-delay=".6s" data-duration="2s">Embark on the future of transportation with our cutting-edge ecosystem powered by $SEMI and $SFUEL tokens. From exclusive NFT access to pioneering the fractional ownership of semi-trucks, we offer a unique blend of benefits and functionalities designed for our community. Join us in driving innovation and shaping the governance of a new digital frontier. Be a part of our journey today.</p>
                    <div className="banner-btn-wrap">
                      <a href="/#mint" className="btn" target="_blank" data-animation="fadeInLeft" data-delay=".8s" data-duration="2s" >
                        nft mint
                      </a>
                      <a href="/#" className="btn"  data-animation="fadeInRight" data-delay=".8s" data-duration="2s" onClick={() => this.handleShow() }>
                        buy token
                      </a>
                      <a href="/#" className="btn"  data-animation="fadeInRight" data-delay=".8s" data-duration="2s" onClick={() => this.openModal() }>
                        Discord/Telegram
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-slider">
          <div className="banner-area banner-bg">
         
          <video className="bannerVideo" autoPlay muted loop poster="assets/img/banner/banner_bg02.jpg">
              <source src="assets/img/banner/bg-2.mp4" type="video/mp4" />
            </video>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner-content text-center">
                    <h4 className="sub-title" data-animation="fadeInUp" data-delay=".2s" data-duration="2s">Staking DApp</h4>
                    <h2 className="title" data-animation="fadeInUp" data-delay=".4s" data-duration="2s">Trucking Empire</h2>
                    <p data-animation="fadeInUp" data-delay=".6s" data-duration="2s">Earn rewards with your NFTs while keeping them in your possession. No need to lock your NFTs in a contract. Soft staking your NFTs allows you to earn $SEMI tokens as rewards while maintaining full control over your assets. Your NFTs stay with you, no locking required, ensuring you can continue to enjoy them even as you participate in our ecosystem. It's the perfect way to contribute to the governance of our platform, earn rewards, and keep your NFTs safe and sound.</p>
                    <div className="banner-btn-wrap">
                      <a href="/#staking" className="btn" target="_blank" data-animation="fadeInLeft" data-delay=".8s" data-duration="2s">
                        NFT Staking
                      </a>
                      <a href="/#" className="btn"  data-animation="fadeInRight" data-delay=".8s" data-duration="2s" onClick={() => this.handleShow() }>
                        buy token
                      </a>
                      <a href="/#" className="btn"  data-animation="fadeInRight" data-delay=".8s" data-duration="2s" onClick={() => this.openModal() }>
                        Discord/Telegram
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
        
        <Modal 
                show={this.state.show} 
                onHide={()=>this.setState({show:false})}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Body className='p-5'>
                            <p><a className='btn d-block btn-primary' href='/#' rel='noreferrer' target='_blank'>Pink Sale</a></p>
                            <p><a className='btn d-block' href="https://trucking-empire-swap.vercel.app/" rel='noreferrer' target='_blank'> Truck-Swap</a></p>
                            <p><a className='btn d-block btn-success' href='https://trucking-empire-swap.vercel.app/uniswap' rel='noreferrer' target='_blank'>UniSwap</a></p>
                            {/* <p><a className='btn d-block btn-danger' href='/#' rel='noreferrer' target='_blank'>Hotbit</a></p> */}
                            {/* <p><a className='btn d-block btn-secondary' href='/#' rel='noreferrer' target='_blank'>Probit</a></p> */}
                            
                    </Modal.Body>
                </Modal>
                <Modal
                show={this.state.modal} 
                onHide={()=>this.setState({modal:false})}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Body className='p-5'>
                            <p>
                                <a 
                                className='btn d-block' 
                                target="_blank" 
                                rel="noreferrer" 
                                href='https://t.me/Trucking_Empire'  
                                style={{background : '#0077b5'}}>
                                    <i className="fab fa-telegram" style={{marginRight:'15px'}}></i>
                                    <span>Telegram</span>
                                </a>
                            </p>
                            <p>
                                <a 
                                className='btn d-block' 
                                target="_blank" 
                                rel="noreferrer" 
                                href='https://discord.gg/truckingempire'  
                                style={{background : '#FF4301'}}>
                                    <i className="fab fa-discord" style={{marginRight:'15px'}}></i>
                                    <span>Discord</span>
                                </a>
                            </p>
                    </Modal.Body>
                </Modal>
      

  </div>
  )
}
}

export default MainSlider