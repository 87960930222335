import React, { useEffect, useState } from "react";
import $ from "jquery";
import "magnific-popup";
import Slider from "react-slick";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import ReactPlayer from 'react-player';
import GoldVideo from '../../assets/img/Gold.MP4';
import SilverVideo from '../../assets/img/Silver.MP4';
import GoldImage from '../../assets/img/gold.png';
import SilverImage from '../../assets/img/silver.png';
import MembershipNFTABI from '../../components/abi/MembershipNFTABI.json';

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa-solid fa-angle-left"></i>
    </button>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa-solid fa-angle-right"></i>
    </button>
  );
}

function MembershipArea() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1, slider2;
  const [contract, setContract] = useState(null);
  const [silverCost, setSilverCost] = useState(null);
  const [goldCost, setGoldCost] = useState(null);
  const [playingSilver, setPlayingSilver] = useState(false);
  const [playingGold, setPlayingGold] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, [slider1, slider2]);

  useEffect(() => {
    const initContract = async () => {
      if (walletProvider) {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const membershipContract = new Contract(
          "0x4963D46bE0a62292c3377b66a4F1Bf11Fd8bde5c", // Replace with your contract address
          MembershipNFTABI,
          signer
        );
        setContract(membershipContract);

        // Fetch membership costs
        const fetchedSilverCost = await membershipContract.silverCost();
        const fetchedGoldCost = await membershipContract.goldCost();
        setSilverCost(formatUnits(fetchedSilverCost, 18)); // Convert to ether
        setGoldCost(formatUnits(fetchedGoldCost, 18)); // Convert to ether
      }
    };

    initContract();
  }, [walletProvider]);

  const mintMembership = async (membershipType) => {
    if (!contract) return;

    try {
      const cost = membershipType === 0 ? silverCost : goldCost;
      if (!cost) {
        alert("Failed to fetch membership cost.");
        return;
      }

      const tx = await contract.purchaseMembership(membershipType, {
        value: parseUnits(cost, "ether"), // Convert to wei
      });
      await tx.wait();
      alert("Membership NFT minted successfully!");
    } catch (error) {
      console.error("Minting failed", error);
      alert(`Minting failed: ${error.reason}`);
    }
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".membership-active",
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const videoContainerStyle = {
    position: 'relative',
    border: '5px solid #ffa500', // Orange-yellow color for the border
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginTop: '20px'
  };

  const membershipItemStyle = {
    backgroundColor: '#f5f5f5', // Light grey background
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px'
  };

  const membershipItemTitleStyle = {
    color: '#fff' // Dark text color
  };

  const playButtonStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer'
  };

  const handlePlayClick = (setPlaying) => {
    setPlaying(true);
  };

  return (
    <section id="mint" className="gallery-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center mb-60">
              <h2 className="title">EXPLORE OUR MEMBERSHIP PERKS</h2>
              <p>Join our exclusive membership program and enjoy a plethora of benefits tailored to enhance your experience and growth within our community. From insider gaming updates to participation in NFT sales, our memberships are designed to offer unparalleled value and access.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-11">
            <Slider
              className="gallery-active"
              asNavFor={nav2}
              ref={(slider) => (slider1 = slider)}
              arrows={false}
            >
              <div className="gallery-item" style={membershipItemStyle}>
                <h3 style={membershipItemTitleStyle}>Silver Membership</h3>
                <p>{silverCost ? `${silverCost} MATIC/Per Year` : "Loading cost..."}</p>
                <ul>
                  <li>Access to Swap and Liquidity Services: Members can swap tokens for semi-governance tokens and participate in adding liquidity, earning fees in the process.</li>
                  <li>Insider Gaming Updates: Gain access to exclusive gameplay information and insights through our private Telegram groups.</li>
                  <li>Dispatcher Courses: Our comprehensive dispatcher courses empower members to become independent contractors within the trucking industry.</li>
                  <li>NFT Verification: Purchase your Silver Membership NFT on our marketplace website and seamlessly verify it on our Discord server and website.</li>
                </ul>
                <div style={videoContainerStyle}>
                  {playingSilver ? (
                    <ReactPlayer
                      url={SilverVideo}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={playingSilver}
                      onError={(error) => console.error("Error loading video:", error)}
                    />
                  ) : (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                      <img src={SilverImage} alt="Silver Membership" width="100%" height="100%" />
                      <div 
                        style={playButtonStyle} 
                        onClick={() => handlePlayClick(setPlayingSilver)}
                      >
                        <svg
                          x="0px"
                          y="0px"
                          viewBox="0 0 213.7 213.7"
                          enableBackground="new 0 0 213.7 213.7"
                          xmlSpace="preserve"
                          width="50px"
                          height="50px"
                        >
                          <circle
                            className="circle"
                            fill="none"
                            stroke="#ffa500"
                            strokeWidth={7}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            cx="106.8"
                            cy="106.8"
                            r="103.3"
                          />
                          <polygon
                            className="triangle"
                            fill="none"
                            stroke="#ffa500"
                            strokeWidth={7}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            points="73.5,62.5 148.5,105.8 73.5,149.1"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="gallery-item" style={membershipItemStyle}>
                <h3 style={membershipItemTitleStyle}>Gold Membership</h3>
                <p>{goldCost ? `${goldCost} MATIC/Per Year` : "Loading cost..."}</p>
                <ul>
                  <li>Includes all Silver Membership Benefits</li>
                  <li>Participation in Fractional Semi Truck NFT Sale: Gain exclusive access to our fractional semi-truck NFT sale.</li>
                  <li>Voting Rights for Truck Ownership: Exercise your voting rights for each truck you purchase NFTs in.</li>
                </ul>
                <div style={videoContainerStyle}>
                  {playingGold ? (
                    <ReactPlayer
                      url={GoldVideo}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={playingGold}
                      onError={(error) => console.error("Error loading video:", error)}
                    />
                  ) : (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                      <img src={GoldImage} alt="Gold Membership" width="100%" height="100%" />
                      <div 
                        style={playButtonStyle} 
                        onClick={() => handlePlayClick(setPlayingGold)}
                      >
                        <svg
                          x="0px"
                          y="0px"
                          viewBox="0 0 213.7 213.7"
                          enableBackground="new 0 0 213.7 213.7"
                          xmlSpace="preserve"
                          width="50px"
                          height="50px"
                        >
                          <circle
                            className="circle"
                            fill="none"
                            stroke="#ffa500"
                            strokeWidth={7}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            cx="106.8"
                            cy="106.8"
                            r="103.3"
                          />
                          <polygon
                            className="triangle"
                            fill="none"
                            stroke="#ffa500"
                            strokeWidth={7}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            points="73.5,62.5 148.5,105.8 73.5,149.1"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="gallery-two-bottom">
          <div className="gallery-nav" />
          <Slider
            className="gallery-nav-active"
            {...settings}
            asNavFor={nav1}
            swipeToSlide={true}
            focusOnSelect={true}
            ref={(slider) => (slider2 = slider)}
            slidesToShow={1}
            arrows={true}
          >
            <h4>Silver Membership</h4>
            <h4>Gold Membership</h4>
          </Slider>
        </div>
        <div className="gallery-two-bottom">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <button className="btn" onClick={() => mintMembership(0)}>
              Mint Silver Membership
            </button>
            <button className="btn" onClick={() => mintMembership(1)}>
              Mint Gold Membership
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MembershipArea;
