import React from "react";

function AboutArea() {
  return (
    <section id="about" className="about-area about-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-8 col-lg-6">
            <div className="about-title">
              <h2 className="title">STEER INTO THE FUTURE</h2>
              <div className="about-content">
                <h6 className="rotate-content">Fractional-NFTs</h6>
                <h2 className="title">Join Our DISCORD for Early Access</h2>
                <h2 className="title">
                  Secure Your Stake in "Blue Beacon" & "Night Rider"
                </h2>
                <p>
                  At the forefront of merging the virtual with reality, Trucking
                  Empire introduces a groundbreaking opportunity with "Blue
                  Beacon" and "Night Rider" - our flagship fractionalized
                  semi-truck NFTs. With each truck divided into 2,000 shares,
                  investors have a unique chance to own a piece of the future of
                  trucking. This innovative approach allows for broader access
                  and participation in our ecosystem, blending the excitement of
                  NFT ownership with the tangible asset of semi-trucks.
                </p>
                <p>
                  Dive deeper into the world of Trucking Empire, where
                  blockchain meets the open road. $SEMI and $SFUEL tokens power
                  an ecosystem that rewards your engagement and investment. Join
                  our Discord for early access, and don't miss out on the chance
                  to be a part of the future of transportation.
                </p>

                <h4 className="small-title">NFT Minting Details</h4>
                <h6 className="price">
                  1 Share of an<span> NFT = TBD </span>{" "}
                </h6>

                <h4 className="small-title">Token Launch Details</h4>
                

                <h6 className="price">
                  <span> Deploy and Airdrop to all Membership holders</span>
                </h6>
               
                
              </div>

              <div className="about-btn-wrap">
                <a href="https://discord.gg/truckingempire" className="btn discord" target="_blank">
                  <i className="fa-brands fa-discord" /> join discord now
                </a>
                <a href="https://opensea.io/collection/trucking-empire-membershipnft" className="btn" target="_blank">
                  OPENSEA
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="about-img-wrap">
              <img src="assets/img/images/about_img.gif" alt="" />
            </div>
          </div>
        </div>
        <div className="s-about-wrap">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6">
              <div className="s-about-title">
                <h2 className="title">
                  BUILD YOUR EMPIRE <span>ON THE OPEN ROAD</span>
                </h2>
              </div>
              <div className="s-about-content">
                <p>
                  At the heart of Trucking Empire lies a groundbreaking
                  integration of the trucking industry, immersive gaming
                  experiences, and the revolutionary technology of non-fungible
                  tokens (NFTs). Our mission is to transform the traditional
                  trucking business into an engaging, digital-first adventure,
                  enabling players and investors alike to navigate the highways
                  of the future.
                </p>
                <p>
                  Trucking Empire introduces "Blue Beacon" and "Night Rider,"
                  our premier semi-truck NFTs, representing more than just
                  digital assets; they symbolize fractional ownership in a fleet
                  of real-world trucks. This novel approach allows you to
                  directly impact and benefit from the operations, earnings, and
                  growth of your investments. Engage in our strategic dispatcher
                  courses to maximize your returns and influence in the
                  logistics world.
                </p>
                <p>
                  Our virtual trucking platform is not just about ownership and
                  investment; it's an expansive, multiplayer gaming environment
                  where trucking enthusiasts can drive, compete, and
                  collaborate. From executing logistics missions to
                  participating in competitive trucking leagues, your actions
                  contribute to both your success and the community's
                  prosperity.
                </p>
                <p>
                  Powered by the $SEMI and $SFUEL tokens, Trucking Empire is a
                  pioneer in integrating gaming mechanics with real economic
                  value, backed by the transparency and security of blockchain
                  technology. As we gear up for the next phase of our journey,
                  we invite you to join our community, stake your claim, and
                  help shape the future of trucking.
                </p>
                <p>
                  For an in-depth exploration of our vision, and the innovative features of Trucking Empire, visit our{" "}
                  <a href="/">Main Website</a> and delve into our World of Trucking.
                  Together, we're not just playing the game; we're changing the
                  game.
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="s-about-img">
                <img src="assets/img/images/about_img02.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutArea;
