import React,{useEffect} from 'react'
import { gsap } from "gsap";
import {  ScrollTrigger } from "gsap/ScrollTrigger";

function Roadmap() {
	useEffect(()=>{
		gsap.registerPlugin(ScrollTrigger)
      // ScrollTrigger.create({
      //     trigger:'.partners-area',
      //     start:"bottom 80px",
      //     endTrigger:'.area-bg',
      //     end:'top bottom',
      //     pin:'.roadmap-area',
      //     pinSpacing:false,
      //     markers:true,
      // })
      ScrollTrigger.create({
          trigger:'.roadmap-head',
          start:"top 150px",
          endTrigger:'.area-bg',
          end:'top bottom',
          pin:'.roadmap-head',
          pinSpacing:false,
          onEnter:() =>{
              gsap.to('.roadmap-head .title', {
                  xPercent:-10,
                  fontSize:35
              })
          },
          // markers:true,
      })


      gsap.to(".timeline-img, .timeline-dots", {
          scrollTrigger: {
              trigger: ".timeline-img, .timeline-dots",
              scrub: true,
              start: "top 300px",
              endTrigger:'.area-bg',
              end: 'top bottom',
              onLeaveBack:() =>{
                  gsap.to('.roadmap-head .title', {
                      xPercent:0,
                      fontSize:60
                  })
              },
              // markers:true,
          },
          y: document.querySelector('.timeline-wrap').clientHeight - 410,
          duration:4,
          zIndex:-1
      })

      // gsap.to(".timeline-img, .timeline-dots", {
      //     scrollTrigger: {
      //         trigger: ".timeline-img, .timeline-dots",
      //         scrub: true,
      //         start: "top 300px",
      //         endTrigger:'.area-bg',
      //         end: 'top bottom',
      //         scroller:'.timeline',
              
      //     },
      //     y: document.querySelector('.timeline-wrap').clientHeight - 410,
      //     duration:4,
      //     zIndex:-1
      // })
	},[])
  return (
    <section id="roadmap" className="roadmap-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title mb-55 roadmap-head">
              <h2 className="title">Trucking Empire <span>ROADMAP</span></h2>
            </div>
          </div>
        </div>
        <div className="timeline">
          <div className="row justify-content-end">
            <div className="col-xl-8 col-lg-9 col-md-7">
              <div className="timeline-wrap">
                <div className="timeline-img">
                  <img src="assets/img/images/timeline_img.png" alt="Trucking Empire Timeline" />
                </div>
                <div className="timeline-content">
                  <p>Trucking Empire is pioneering the integration of the trucking industry with blockchain technology. Our roadmap outlines two ambitious paths: the development of a blockchain-based game that offers deep insights into the trucking business, and the innovative fractionalization of real-world semi-trucks into NFTs, providing tangible value and utility. Join us as we embark on this dual journey, forging a new era in both the digital and physical realms of trucking.</p>
                </div>
                {/* Phase 1: Foundations */}
                <div className="timeline-item">
                  <div className="timeline-list">
                    <h4 className="title">Phase 1 - Foundations & Launch</h4>
                    <ul>
                      <li>Legal entity establishment for operational structure</li>
                      <li>Apply for Department of Transportation credentials for real-world truck operations</li>
                      <li>Launch of the NFT Minting DApp for semi-trucks</li>
                      <li>Smart contract development</li>
                      
                      <li>Website Launch</li>
                      <li>Social Media Launch</li>
                      <li>Audit Launch</li>
                      <li>NFT-Marketplace Development</li>
                      <li>Game-Trailer Development</li>
                      <li>Whitepaper Launch</li>
                      <li>Create & Deploy ERC20 Contracts</li>
                      <li>Liquidity Pool Creation</li>
                      <li>Airdrop</li>
                      <li>NFT Minting DApp Development</li>
                      <li>Staking DApp Development</li>
                      <li>Register with the S.E.C</li>
                      <li>Launch targeted Advertisement Campaigns</li>
                      <li>Engage in Social Media Marketing and Discord Community Building</li>
                      <li>Develop Private Placement Memorandum for Fractional Semi Truck Ownership</li>
                      <li>Launch Dispatcher Courses</li>
                      <li>Develop Fractional Contract for Asset Ownership</li>
                      <li>Begin Pre-game Development </li>
                      <li>Comprehensive marketing and community engagement campaign kickoff</li>
                    </ul>
                  </div>
                </div>
                {/* Phase 2: Ecosystem Development */}
                <div className="timeline-item">
                  <div className="timeline-list">
                    <h4 className="title">Phase 2 - Ecosystem Development</h4>
                    <ul>
                      <li>Development of the Trucking Empire game, introducing the gaming community to trucking logistics</li>
                      <li>Launch of the fractional semi-truck NFT marketplace, bridging the digital and physical trucking worlds</li>
                      <li>Expansion of educational content through dispatcher courses, enhancing industry knowledge</li>
                      <li>Introduction of in-game and real-world utility for NFTs, emphasizing the fusion of gaming with tangible assets</li>
                      <li>Launch of the Trucking Empire staking platform, offering rewards and incentives for community participation</li>
                      <li>Integration of $SEMI and $SFUEL tokens into the Trucking Empire ecosystem, driving economic value and governance</li>
                      <li>Continued marketing efforts to raise awareness and attract new users to the Trucking Empire platform</li>
                      <li>Community events and partnerships to foster engagement and growth within the Trucking Empire ecosystem</li>
                      <li>Development of the Trucking Empire mobile app, enabling on-the-go access to the platform and NFTs</li>
                      <li>Further development of the Trucking Empire game, adding new features and functionalities for players</li>
                      <li>Expansion of the fractional semi-truck NFT collection, offering a diverse range of trucks for ownership</li>
                      <li>Implementation of governance features and community voting to shape the future of the platform</li>
                      <li>Launch of the Trucking Empire staking platform, providing additional rewards and incentives for users</li>
                      <li>Integration of real-world trucking logistics into the game, offering a comprehensive experience for players</li>
                      <li>Continued marketing campaigns to attract new users and grow the Trucking Empire community</li>

                    </ul>
                  </div>
                </div>
                {/* Phase 3: Growth & Integration */}
                <div className="timeline-item">
                  <div className="timeline-list">
                    <h4 className="title">Phase 3 - Growth & Integration</h4>
                    <ul>
                      <li>Strategic partnerships to expand the ecosystem's reach and utility</li>
                      <li>Advanced game development, integrating more NFT functionalities and real-world trucking logistics</li>
                      <li>Enhancement of the fractional ownership model, offering more semi-trucks as NFTs</li>
                      <li>Community-driven events and charity initiatives to strengthen engagement</li>
                      <li>Launch of the Trucking Empire mobile app, enabling on-the-go access to the platform</li>
                      <li>Further development of the staking platform, providing additional rewards and governance features</li>
                      <li>Implementation of governance proposals and community voting to shape the platform's future</li>
                      <li>Expansion of educational content and industry partnerships to promote trucking knowledge and innovation</li>
                      <li>Continued marketing campaigns to attract new users and grow the Trucking Empire community</li>

                    </ul>
                  </div>
                </div>
                {/* Phase 4: Full Scale Launch */}
                <div className="timeline-item">
                  <div className="timeline-list">
                    <h4 className="title">Phase 4 - Full Scale Launch</h4>
                    <ul>
                      <li>Official launch of the Trucking Empire game with full features and blockchain integration</li>
                      <li>Expansion of the fractional semi-truck NFT collection, including rare and specialized trucks</li>
                      <li>Continuous marketing efforts to grow the Trucking Empire community and user base</li>
                      <li>Further innovations in blockchain technology to enhance the trucking industry</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;