import React from 'react'

function Ecosystem() {
  return (
    <section id="whitepapers" className="ecosystem-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6">
            <div className="s-about-title">
              <h2 className="title">BLOCKCHAIN INTEGRATION <span>IN LOGISTICS</span></h2>
            </div>
            <div className="s-about-content">
              <p>The logistics industry, valued at over $8 trillion globally, stands on the brink of a technological revolution, with blockchain technology at its core. This integration promises unparalleled transparency, efficiency, and security across supply chains. Recent studies indicate that blockchain can reduce transportation and customs processing costs by 20%, potentially saving billions annually.</p>
              <p>Blockchain's decentralized nature ensures a tamper-proof record of transactions and asset movement, offering real-time tracking and verification capabilities. With over 30% of logistics companies already exploring blockchain solutions, the industry is set to embrace a more reliable, cost-effective, and secure future. Trucking Empire leverages this cutting-edge technology, creating a unique NFT ecosystem that transforms truck ownership and logistics operations.</p>
              <p>Our platform is designed to be at the forefront of this transformation, providing fractional ownership of semi-trucks through NFTs and enhancing operational transparency. By investing in Trucking Empire, you're not just participating in a digital marketplace; you're contributing to a more sustainable and efficient global logistics network.</p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="s-about-img">
              <img src="assets/img/images/eco_img.png" alt="Blockchain Integration" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Ecosystem
