import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <section id="contact" className="contact-area pt-115 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="contact-discord text-center">
              <FontAwesomeIcon icon={faDiscord} size="6x" color='#ffa500' />
              <h2 className="title mt-4">Join Our Discord Community!</h2>
              <p>Connect, get support, and discuss with fellow members.</p>
              <a href="https://discord.gg/truckingempire" target="_blank" rel="noopener noreferrer" className="btn mt-3">Join us on Discord</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
