import React, {useState} from 'react'

function Footer() {
    const [searchTerm, setSearchTerm] = useState('');
  const [easterEggFound, setEasterEggFound] = useState(false);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue && !easterEggFound) {
      alert('Congrats, you found your first clue! Now head to /easter-egg to find the next!');
      setEasterEggFound(true); // Prevents the message from appearing more than once
    }
  };
  return (
	<footer>
        <div className="footer-top-wrap">
          <div className="container footer-container">
            <div className="footer-menu-wrap">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="f-logo">
                    <a href="/#"><img src="assets/img/logo/logo.png" alt="" /></a>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="footer-menu">
                    <nav>
                      <ul className="navigation">
                        <li><a href="https://www.truckingempire.io/" target="_blank">TRUCKING EMPIRE WEBSITE</a></li>
                        <li><a href="https://trucking-empire-swap.vercel.app/" target="_blank">SWAP</a></li>

                        <li><a href="/#about" target="_blank">ABOUT</a></li>
                        <li><a href="/#mint" target="_blank">MINT</a></li>
                        <li><a href="/#staking" target="_blank">STAKING</a></li>
                        {/* <li><a href="/#roadmap" target="_blank">ROADMAP</a></li>
                        <li><a href="/#contact" target="_blank">CONTACT</a></li> */}
                      </ul>
                      <div className="footer-search">
                  <form>
                    <input 
                      type="text" 
                      placeholder="Find Here Now" 
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <button type="button"><i className="fa-solid fa-magnifying-glass" /></button>
                  </form>
                </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-quick-link-wrap">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-8">
                  <div className="quick-link-list">
                    <ul>
                      <li><a href="/#faq" target="_blank">FAQ</a></li>
                      <li><a href="https://discord.gg/truckingempire" target="_blank">Help Center</a></li>
                      <li><a href="/terms" target="_blank">Terms of Use</a></li>
                      <li><a href="/privacy" target="_blank">Privacy</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4">
                  <div className="footer-social">
                    <ul>
                  <li>
                    <a target="https://www.instagram.com/truckingempire.io/" rel="noreferrer" href="/#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="/#">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a target="https://www.youtube.com/channel/UCczAXqblBuCHM4hJm3YlcPQ" rel="noreferrer" href="/#">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a target="_blank" rel="noreferrer" href="/#">
                    <i className="fab fa-tiktok"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="/#">
                      <i className="fab fa-medium"></i>
                    </a>
                  </li> */}
                  <li>
                    <a target="https://twitter.com/Trucking_Empire" rel="noreferrer" href="/#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                
                  <li>
                    <a target="https://discord.gg/truckingempire" rel="noreferrer" href="/#">
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>
                </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text text-center">
                  <p>Copyright Â© 2024 All Rights Reserved By <span>Trucking Empire</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
