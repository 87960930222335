import React, { useEffect } from "react";
import $ from "jquery";
import "magnific-popup";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { BrowserProvider, Contract } from 'ethers';

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa-solid fa-angle-left"></i>
    </button>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa-solid fa-angle-right"></i>
    </button>
  );
}

function ParkingNFT() {
  const Mint = async () => {
    try {
      const web3Modal = createWeb3Modal(defaultConfig);
      const provider = new BrowserProvider(await web3Modal.connect());
      const signer = provider.getSigner();
      const contract = new Contract('0xd01886Dda3d3C4E4d4559D8E85f991EaD7bBcb0d', YOUR_ABI, signer);
      const transaction = await contract.mintParkingNFT();
      await transaction.wait();
      alert('Minting successful!');
    } catch (error) {
      console.error(error);
      alert('Minting failed!');
    }
  };

  useEffect(() => {
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);

  return (
    <section id="mint" className="parking-area" style={{ padding: "50px 0" }}>
      <div className="container" style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center mb-60">
              <h2 className="title">MINT A PARKING NFT</h2>
              <p>Purchase our exclusive NFT Semi Truck Parking slots and enjoy a range of benefits designed to streamline your trucking operations. From secure parking spaces to additional amenities, our NFTs offer unparalleled value and convenience.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-11">
            <div className="parking-item" style={{ position: 'relative', textAlign: 'center', border: '10px solid orange', borderRadius: '15px', overflow: 'hidden' }}>
              <img src="assets/img/parking.png" alt="Parking Slot 1" style={{ marginBottom: '100px', width: '100%', height: 'auto', position: 'relative', zIndex: 2 }} />
              <a href="assets/img/parking.mp4" className="popup-video" style={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 3, textDecoration: 'none'
              }}>
                <svg x="0px" y="0px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve" style={{ width: '80px', height: '80px' }}>
                  <circle className="circle" fill="none" stroke="#FFF" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} cx="106.8" cy="106.8" r="103.3" />
                  <polygon className="triangle" fill="#FFF" strokeWidth={7} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} points="73.5,62.5 148.5,105.8 73.5,149.1" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="parking-two-bottom" style={{ marginTop: "50px" }}>
          <div className="row">
            <div className="col-lg-6">
              <h4>Access to Swap and Liquidity Services</h4>
              <p>Members can swap tokens for semi-governance tokens and participate in adding liquidity, earning fees in the process.</p>
            </div>
            <div className="col-lg-6">
              <h4>Secure Parking Spaces</h4>
              <p>Park your semi truck in our designated and secure parking slots, ensuring peace of mind.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h4>Additional Amenities</h4>
              <p>Enjoy access to rest areas, maintenance services, and more.</p>
            </div>
            <div className="col-lg-6">
              <h4>NFT Verification</h4>
              <p>Purchase your Parking NFT on our marketplace website and seamlessly verify it on our Discord server and website.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h4>Cost: $100 worth of $SEMI token annually</h4>
              <p>Duration: Full year. Sales Policy: All sales are final.</p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
            <button className="btn" onClick={Mint}>
              MINT
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ParkingNFT;
