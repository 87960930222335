import React from 'react';

class TermsOfUseSection extends React.Component {
    render() {
        return (
            <div id="terms" className='py-5'>
                <div className='container'>
                    <div className='row py-5'>
                        <div className='col-md-12'>
                            <div className="section-title text-center py-5">
                                <h2 className="title text-capitalize">Terms of Use</h2>
                            </div>
                            <p className='text-center pb-5 mt-3'>Welcome to Trucking Empire. The following terms and conditions govern all use of the Trucking Empire website and all content, services, and products available at or through the website, including, but not limited to, Trucking Empire NFTs (Non-Fungible Tokens), staking services, and our gaming platform (taken together, the Services). The Services are offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Trucking Empireâs Privacy Policy), and procedures that may be published from time to time by Trucking Empire (collectively, the âAgreementâ).</p>
                            
                            <h3>1. Acceptance of Terms</h3>
                            <p>By accessing or using any part of the Services, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.</p>
                            
                            <h3>2. NFT Ownership and Rights</h3>
                            <p>When you purchase or otherwise acquire an NFT from Trucking Empire, you receive a digital certificate of ownership of a limited license to use the NFT. Ownership of the NFT is governed entirely by the smart contract and blockchain network of [specify blockchain, e.g., Ethereum].</p>
                            
                            <h3>3. Staking</h3>
                            <p>Users may have the option to stake certain NFTs within Trucking Empireâs platform to earn rewards. Staking is subject to terms that will be provided at the time of staking. You understand the risks associated with staking, including but not limited to the potential loss of staked NFTs.</p>
                            
                            <h3>4. Use of Services</h3>
                            <p>You are responsible for your actions when using Trucking Empireâs Services. Prohibited actions include, but are not limited to, engaging in illegal activities, infringing on intellectual property, and conducting transactions with stolen or unauthorized payment methods.</p>
                            
                            <h3>5. Disclaimer of Warranties</h3>
                            <p>The Services are provided âas isâ. Trucking Empire and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Trucking Empire nor its suppliers and licensors make any warranty that the Services will be error free or that access thereto will be continuous or uninterrupted.</p>
                            
                            <h3>6. Limitation of Liability</h3>
                            <p>In no event will Trucking Empire, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data.</p>
                            
                            <h3>7. General Representation and Warranty</h3>
                            <p>You represent and warrant that your use of the Services will be in strict accordance with the Trucking Empire Privacy Policy, with this Agreement, and with all applicable laws and regulations.</p>
                            
                            <h3>8. Changes</h3>
                            <p>Trucking Empire reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Services following the posting of any changes to this Agreement constitutes acceptance of those changes.</p>
                            
                            <h3>9. Contact Information</h3>
                            <p>For any questions regarding these Terms of Use, please contact us at [insert contact information].</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsOfUseSection;
