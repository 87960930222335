import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import EasterEggPage from './components/main/EasterEggPage';
import PrivacyPolicySection from './components/main/Privacy';
import TermsOfUseSection from './components/main/Terms';
import ScrollToTopRoute from './components/main/scrolltotop/ScrollToTopRoute';
import { web3Modal } from './utils/web3modalConfig.jsx'; // Adjust the import path as necessary

function App() {
  return (
    <web3Modal> {/* Wrap your components with web3Modal */}
      <div className="App">
        <Header />
        <Router>
          <Switch>
            <ScrollToTopRoute exact path="/">
              <Home />
            </ScrollToTopRoute>
            <Route path="/privacy">
              <PrivacyPolicySection />
            </Route>
            <Route path="/terms">
              <TermsOfUseSection />
            </Route>
            <Route path="/easter-egg">
              <EasterEggPage />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </div>
    </web3Modal>
  );
}

export default App;
