import React from 'react';

function EasterEggPage() {
    return (
        <section id="easter-egg" className="easter-egg-page py-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title text-center py-5">
                            <h2 className="title">You Found It!</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="easter-egg-content text-center">
                            <p className="lead">Congratulations on discovering the first Easter egg. You're on your way to uncovering the mysteries of Trucking Empire!</p>
                            <p>Here's your next clue:</p>
                            <blockquote className="next-clue">
                                "In the world of logistics and NFTs, where trucks roam free, the next secret lies where the community meets."
                            </blockquote>
                            <p>Follow the clues, solve the mystery, and uncover the secrets of Trucking Empire. Good luck on your journey!</p>
                            <a href="/community" className="btn btn-primary mt-4">Go to Community Page</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EasterEggPage;
