import React, { useState, useEffect } from "react";
import { ReactFloatingBalloons } from "react-floating-balloons";

// Mock function to simulate fetching user's NFTs
const fetchUserNFTs = async () => {
  return [
    {
      id: "NFT1",
      name: "Truck #001",
      image: "/path/to/image1.png",
      description: "Fractional semi-truck NFT for soft staking.",
    },
    {
      id: "NFT2",
      name: "Truck #002",
      image: "/path/to/image2.png",
      description: "Fractional semi-truck NFT for soft staking.",
    },
    // Add more NFTs as needed
  ];
};

function StakingDApp() {
  const [userNFTs, setUserNFTs] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState("");
  const [stakingFeedback, setStakingFeedback] = useState("");
  const [balloonsVisible, setBalloonsVisible] = useState(false); // State to control the visibility of balloons

  useEffect(() => {
    const loadNFTs = async () => {
      const nfts = await fetchUserNFTs();
      setUserNFTs(nfts);
    };
    loadNFTs();
  }, []);

  const handleOnboardSubmit = (e) => {
    e.preventDefault();
    if (!selectedNFT) {
      setStakingFeedback("Please sign in with your favorite web3 wallet.");
      return;
    }
    console.log("Onboarding to soft staking");
    // Simulate onboarding interaction
    setStakingFeedback(
      `Success! You're now onboarded!`
    );
   
    };
  

  const handleStakeSubmit = (e) => {
    e.preventDefault();
    if (!selectedNFT) {
      setStakingFeedback("Please select an NFT to stake.");
      return;
    }
    console.log("Staking NFT with ID:", selectedNFT);
    // Simulate staking interaction
    setStakingFeedback(
      `Success! You're now soft staking ${selectedNFT}. Your NFT stays with you while you earn rewards.`
    );
    // Here you would typically call a function that interacts with your smart contract
  };

  const handleClaimRewards = () => {
    console.log("Claiming rewards");
    // Simulate claiming rewards interaction
    setStakingFeedback(`Success! Rewards claimed!`);
    setBalloonsVisible(true); // Show balloons when rewards are claimed
  }

  return (
    <section id="staking" className="staking-bg pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-60">
              <h2 className="title">
                <span>Soft Stake Your NFTs</span>
              </h2>
              <p>
                Earn rewards with your NFTs while keeping them in your
                possession. No need to lock your NFTs in a contract.
              </p>
            </div>
          </div>
        </div>
        <div className="staking-form-wrap">
          <form onSubmit={handleStakeSubmit}>
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 mb-4 bg-light p-4">
                <select
                  className="form-control"
                  value={selectedNFT}
                  onChange={(e) => setSelectedNFT(e.target.value)}
                >
                  <option value="">Select NFT to Soft Stake</option>
                  {userNFTs.map((nft) => (
                    <option
                      key={nft.id}
                      value={nft.id}
                    >{`${nft.name} - ${nft.description}`}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                <div>
                  <button onClick={handleOnboardSubmit} className="btn btn-primary mb-4">
                    Onboard
                  </button>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary">
                    Stake
                  </button>
                </div>
                <div>
                  {" "}
                  <button onClick={handleClaimRewards} className="btn btn-primary mt-4">
          Claim
        </button>
        {balloonsVisible && (
          <ReactFloatingBalloons
            count={7} // Number of balloons
            colors={['yellow', 'orange', 'red', 'red', 'orange', 'yellow']} // Balloon colors
            loop={false} // Do not loop the animation
            hangOnTop={false} // Balloons rise to the top
            msgText="Congratulations!" // Message on the balloons
            popVolumeLevel={0.5} // Volume of the pop sound
          />
        )}
                </div>
              </div>
            </div>
          </form>
          {stakingFeedback && (
            <div className="alert alert-info mt-4" role="alert">
              {stakingFeedback}
            </div>
          )}
        </div>
        <div className="row mt-5">
          <h3 className="col-lg-12 text-center">Why Soft Stake NFTs?</h3>
          <p>
            Soft staking your NFTs allows you to earn $SEMI tokens as rewards
            while maintaining full control over your assets. Your NFTs stay with
            you, no locking required, ensuring you can continue to enjoy them
            even as you participate in our ecosystem. It's the perfect way to
            contribute to the governance of our platform, earn rewards, and keep
            your NFTs safe and sound.
          </p>
        </div>
      </div>
    </section>
  );
}

export default StakingDApp;
