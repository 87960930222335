import React, { useEffect } from "react";
import $ from "jquery";
import ConnectButton from "../components/ConnectButton.jsx";

function Header() {
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
  }, []);
  return (
    <header>
      <div className="header-top-area">
        <div className="container">
   
        <div>
          
        </div>
          <div className="row ">
            <div className="col-lg-6 m-auto">
              <div className="header-social justify-content-center">
                <span>Follow us on :</span>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/truckingempire.io/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61557100545386">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>

                  {/* <li>
                    <a target="_blank" rel="noreferrer" href="/#">
                      <i className="fab fa-medium"></i>
                    </a>
                  </li> */}
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://x.com/Trucking_Empire">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>

                  <li>
                    <a target="_blank" rel="noreferrer" href="https://discord.gg/truckingempire">
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>

                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sticky-header" className="menu-area transparent-header">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <img src="assets/img/icon/menu.png" alt="" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo">
                    <a href="/">
                      <img src="assets/img/logo/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-xl-flex">
                    <ul className="navigation">
                      <li className="active">
                        <a href="/#banner">HOME</a>
                      </li>
                      <li>
                        <a href="/#about">ABOUT</a>
                      </li>
                      <li>
                        <a href="/#mint">MINT</a>
                      </li>
                      <li>
                        <a href="/#staking">STAKING</a>
                      </li>
                      <li>
                        <a href="/#roadmap">ROADMAP</a>
                      </li>
                      <li>
                        <a href="/#contact">CONTACT</a>
                      </li>
                      <li>
                        <a href="/#faq">FAQ</a>
                      </li>
                    </ul>
                    
         
       
                  </div>
                  <div className="header-action">
                    <ul>
                      <li className="header-th">
                        <a href="/#" className="menu-tigger">
                          <img src="assets/img/icon/menu.png" alt="" />
                        </a>
                      </li>
                      <li className="header-phone">
                        <div className="phone-icon">
                          <i className="fa-brands fa-discord" />
                        </div>
                        <div className="call">
                          <span>Forums:</span>
                          <a href="https://discord.gg/truckingempire" target="_blank" rel="noreferrer">
                            Discord
                          </a>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                </nav>
              </div>
              <ConnectButton />
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fa-solid fa-xmark" />
                  </div>
                  <div className="nav-logo">
                    <a href="/">
                      <img src="assets/img/logo/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                  </div>
                  <div className="header-phone">
                    <div className="phone-icon">
                      <i className="fa-brands fa-discord" />
                    </div>
                    <div className="call">
                      <span>Forums:</span>
                      <a href="/#" rel="noreferrer" target="_blank">
                        Discord
                      </a>
                    </div>
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="/#">
                          <i className="fa-brands fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fa-brands fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fa-brands fa-twitch" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fa-brands fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>

      <div className="extra-info">
        <div className="close-icon menu-close">
          <button>
            <i className="fa-regular fa-rectangle-xmark" />
          </button>
        </div>
        <div className="side-logo mb-30">
          <a href="/">
            <img src="assets/img/logo/logo.png" alt="Logo" />
          </a>
        </div>
        <div className="side-info mb-30">
          <div className="contact-list mb-30">
            <h4>Office Address</h4>
            <p>
              Texas <br /> United States
            </p>
          </div>
          <div className="contact-list mb-30">
            <h4>Phone Number</h4>
            <p>+1(804)380-7109</p>
          </div>
          {/* <div className="contact-list mb-30">
            <h4>Email Address</h4>
            <p>info@example.com</p>
            <p>example.mail@hum.com</p>
          </div> */}
          <div className="contact-list mb-30">
            <h4>Official-Links</h4>
            <a href="https://www.truckingempire.io/">
              https://www.truckingempire.io/
            </a>
            <div className="mt-2">
            <a href="https://www.truckingempire.io/">
              https://trucking-empire-swap.vercel.app/
            </a>
            </div>
          </div>
        </div>
        <ul className="side-instagram">
          <li>
            <a href="/#">
              <img src="assets/img/images/sd_insta01.jpg" alt="" />
            </a>
          </li>
          <li>
            <a href="/#">
              <img src="assets/img/images/polygon.gif" alt="" />
            </a>
          </li>
          <li>
            <a href="/#">
              <img src="assets/img/images/nightT.gif" alt="" />
            </a>
          </li>
          <li>
            <a href="/#">
              <img src="assets/img/images/blue.gif" alt="" />
            </a>
          </li>
        </ul>
        <div className="social-icon-right text-center mt-30">
          <a href="/#">
            <i className="fa-brands fa-facebook-f" />
          </a>
          <a href="https://twitter.com/Trucking_Empire">
            <i className="fa-brands fa-twitter" />
          </a>
          <a
            target="https://www.instagram.com/truckingempire.io/"
            rel="noreferrer"
            href="/#"
          >
            <i className="fab fa-instagram"></i>
          </a>{" "}
          <a href="https://www.youtube.com/channel/UCczAXqblBuCHM4hJm3YlcPQ">
            <i className="fa-brands fa-youtube" />
          </a>
          <a target="https://discord.gg/truckingempire" rel="noreferrer" href="/#">
                      <i className="fab fa-discord"></i>
                    </a>
        </div>
      </div>
      <div className="offcanvas-overly" />
      {/* offCanvas-menu-end */}
    </header>
  );
}

export default Header;
