import React from 'react'

function TruckingEmpireFeatures() {
  return (
    <section className="why-trucking-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center mb-60">
              <h2 className="title">WHY SEMI TRUCK FRACTIONALIZATION?</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-6">
            <div className="features-item">
              <div className="features-icon">
                <img src="assets/img/icon/features_icon01.png" alt="" />
              </div>
              <div className="features-content">
                <h4 className="title">INNOVATIVE OWNERSHIP</h4>
                <p>Unlock the future of the trucking industry through fractional semi-truck NFTs. Our innovative platform democratizes investment in real-world trucking assets, allowing for shared ownership and benefits among our community members.</p>
              </div>
              <span className="features-line" />
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="features-item">
              <div className="features-icon">
                <img src="assets/img/icon/features_icon02.png" alt="" />
              </div>
              <div className="features-content">
                <h4 className="title">NFT-BASED LOGISTICS</h4>
                <p>Leverage our NFT marketplace for seamless transactions in the trucking ecosystem. Buy, sell, or lease trucking assets as NFTs, opening up new avenues for operational flexibility and financial liquidity in the logistics industry.</p>
              </div>
              <span className="features-line" />
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="features-item">
              <div className="features-icon">
                <img src="assets/img/icon/features_icon03.png" alt="" />
              </div>
              <div className="features-content">
                <h4 className="title">STAKING & REWARDS</h4>
                <p>Participate in our unique staking program to earn rewards and influence within the Trucking Empire ecosystem. By staking your fractional truck NFTs and $SEMI tokens, gain access to exclusive benefits, enhanced earning opportunities, and a say in the platform's future developments. It's not just about ownership; it's about being an active part of our growing community.</p>
              </div>
              <span className="features-line" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TruckingEmpireFeatures
