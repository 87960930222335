import React, { useEffect, useState } from "react";
import $ from "jquery";
import "magnific-popup";
import Slider from "react-slick";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa-solid fa-angle-left"></i>
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fa-solid fa-angle-right"></i>
    </button>
  );
}

function GalleryArea() {
  
  const Mint = () => {};

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".gallery-active",
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1, slider2;
  useEffect(
    function () {
      setNav1(slider1);
      setNav2(slider2);
    },
    [slider1, slider2]
  );

  useEffect(() => {
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);
  return (
    <section id="mint" className="gallery-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center mb-60">
              <h2 className="title">MINT A SHARE OF OUR TRUCKS/NFTS</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-11">
            <Slider
              className="gallery-active"
              asNavFor={nav2}
              ref={(slider) => (slider1 = slider)}
              arrows={false}
            >
              <div className="gallery-item">
                <img src="assets/img/gallery/trailer00.png" alt="" />
                <a
                  href="assets/img/blue.mp4"
                  className="popup-video"
                >
                  <svg
                    x="0px"
                    y="0px"
                    viewBox="0 0 213.7 213.7"
                    enableBackground="new 0 0 213.7 213.7"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="circle"
                      fill="none"
                      strokeWidth={7}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      cx="106.8"
                      cy="106.8"
                      r="103.3"
                    />
                    <polygon
                      className="triangle"
                      fill="none"
                      strokeWidth={7}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      points="73.5,62.5 148.5,105.8 73.5,149.1"
                    />
                  </svg>
                </a>
              </div>

              <div className="gallery-item">
                <img src="assets/img/gallery/trailer01.png" alt="" />
                <a href="assets/img/night.mp4" className="popup-video">
                  <svg
                    x="0px"
                    y="0px"
                    viewBox="0 0 213.7 213.7"
                    enableBackground="new 0 0 213.7 213.7"
                    xmlSpace="preserve"
                  >
                    <circle
                      className="circle"
                      fill="none"
                      strokeWidth={7}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      cx="106.8"
                      cy="106.8"
                      r="103.3"
                    />
                    <polygon
                      className="triangle"
                      fill="none"
                      strokeWidth={7}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      points="73.5,62.5 148.5,105.8 73.5,149.1"
                    />
                  </svg>
                </a>
              </div>
            </Slider>
          </div>
        </div>
        <div className="gallery-two-bottom">
          <div className="gallery-nav" />
          <Slider
            className="gallery-nav-active"
            {...settings}
            asNavFor={nav1}
            swipeToSlide={true}
            focusOnSelect={true}
            ref={(slider) => (slider2 = slider)}
            slidesToShow={1}
            arrows={true}
          >
            <h4>Blue Beacon</h4>
            <h4>Night Rider</h4>
          </Slider>
        </div>
        <div className="gallery-two-bottom">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <button className="btn" onClick={Mint}>
              MINT
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GalleryArea;
