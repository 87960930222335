import React from 'react';

function Token() {
  return (
    <section className="token-area pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-60">
              <h2 className="title"><span>$SEMI & $SFUEL</span> OUR CORE TOKENS</h2>
            </div>
          </div>
        </div>
        <div className="token-box-wrap">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="token-content mb-65">
                <div className="token-icon mb-20">
                  <img src="assets/img/icon/token_icon.png" alt="" />
                </div>
                <h2 className="title">BREAKING THE BARRIERS</h2>
                <p>Our core tokens, $SEMI and $SFUEL, are the backbone of the Trucking Empire ecosystem. $SEMI is the governance token that allows holders to vote on proposals and shape the future of the game. $SFUEL is the utility token that powers economy</p>
              </div>
              <div className="token-img text-center">
                <img src="assets/img/images/token_img.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="token-item mb-20">
                <div className="token-content-wrap">
                  <h2 className="title">total <span>30%</span></h2>
                </div>
                <ul className="token-list" style={{ wordBreak: 'break-all' }}>
                  <li>
                    Trucking Empire Foundation: 30% (9,000,000 tokens) <br />
                    - 0xFD318ad376daB07D285b134Cc340Cf2340DDDA33
                  </li>
                </ul>
              </div>
              <div className="token-item mb-20">
                <div className="token-content-wrap">
                  <h2 className="title">total <span>20%</span></h2>
                </div>
                <ul className="token-list" style={{ wordBreak: 'break-all' }}>
                  <li>
                    Trucking Empire Marketing: 20% (6,000,000 tokens) <br />
                    - 0xD2C08c32C677b5183956DA9208358f26095D4e33
                  </li>
                </ul>
              </div>
              <div className="token-item mb-20">
                <div className="token-content-wrap">
                  <h2 className="title">total <span>15%</span></h2>
                </div>
                <ul className="token-list" style={{ wordBreak: 'break-all' }}>
                  <li>
                    Trucking Empire P2E Game: 15% (4,500,000 tokens) <br />
                    - 0x341a5867A3885D7312d562FF6405fcfca0e03F4F
                  </li>
                </ul>
              </div>
              <div className="token-item mb-20">
                <div className="token-content-wrap">
                  <h2 className="title">total <span>10%</span></h2>
                </div>
                <ul className="token-list" style={{ wordBreak: 'break-all' }}>
                  <li>
                    Trucking Empire Partnerships/Advisors: 10% (3,000,000 tokens) <br />
                    - 0xE171F28086C678856c9bb84e080B6032461E3A1f
                  </li>
                </ul>
              </div>
              <div className="token-item mb-20">
                <div className="token-content-wrap">
                  <h2 className="title">total <span>15%</span></h2>
                </div>
                <ul className="token-list" style={{ wordBreak: 'break-all' }}>
                  <li>
                    Trucking Empire Staking Rewards: 15% (4,500,000 tokens) <br />
                    - 0x9429285873D612E3EB1347d7C33c297F2409Aa05
                  </li>
                </ul>
              </div>
              <div className="token-item mb-20">
                <div className="token-content-wrap">
                  <h2 className="title">total <span>10%</span></h2>
                </div>
                <ul className="token-list" style={{ wordBreak: 'break-all' }}>
                  <li>
                    Trucking Empire Airdrop: 10% (3,000,000 tokens) <br />
                    - 0x7c01b94C3F06917dcDc0eB119c827e07095fFD78
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Token;
