import React from 'react';

class FaqSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    id: 1,
                    title: "What is Trucking Empire?",
                    description: "Trucking Empire is an innovative platform integrating blockchain with the logistics industry, offering fractional ownership of semi-trucks through NFTs, enhancing efficiency, transparency, and security in logistics operations."
                },
                {
                    id: 3,
                    title: "Who can participate in Trucking Empire?",
                    description: "Anyone interested in the logistics industry, blockchain technology, or NFT investments can participate. We aim to democratize the ownership of trucking assets, making it accessible to a wider audience."
                },
                {
                    id: 4,
                    title: "How does fractional ownership work?",
                    description: "Fractional ownership allows multiple investors to own a share of a semi-truck, represented as an NFT. This model lowers the entry barrier for investment and provides a way to earn passive income from the operations of the truck."
                },
                {
                    id: 6,
                    title: "Can we stake our NFTs?",
                    description: "Yes, NFT staking is part of our ecosystem. By staking your truck NFTs, you can earn rewards, contributing to the overall growth and efficiency of the logistics network."
                },
                {
                    id: 7,
                    title: "Where can we learn more about your platform?",
                    description: "Visit our official website and check out our whitepaper for detailed information on Trucking Empire, our vision, and how we're revolutionizing the logistics industry with blockchain technology."
                }
            ]
        };
    }

    render() {
        return (
            <div id="faq" className='py-5'>
                <div className='container'>
                    <div className='row py-5'>
                        <div className='col-md-8 m-auto'>
                            <div className="section-title text-center py-5">
                                <h2 className="title text-capitalize">Frequently Asked Questions</h2>
                            </div>
                            <p className='text-center pb-5 mt-3'>Below we have provided our summarized frequently asked questions. If you have additional questions, concerns, improvement proposals, or would like to cooperate with us, please get in touch with us using the contact form below.</p>
                        </div>
                    </div>
                </div>
                <div className='faq-area py-5'>
                    <div className="shape shape-top" data-negative="false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 10" preserveAspectRatio="none">
                            <path d="M350,10L340,0h20L350,10z"></path>
                        </svg>
                    </div>
                    <div className='container'>
                        <div className="accordion" id="accordionExample">
                            {this.state.data.map(val => (
                                <div className="accordion-item" key={val.id}>
                                    <h2 className="accordion-header d-flex justify-content-between align-items-center" id={`heading${val.id}`}>
                                        <button className="accordion-button text-white font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${val.id}`} aria-expanded="true" aria-controls={`collapse${val.id}`}>
                                            {val.title}
                                        </button>
                                    </h2>
                                    <div id={`collapse${val.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${val.id}`} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            {val.description}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='shape shape-bottom' data-negative="false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 10" preserveAspectRatio="none">
                            <path d="M350,10L340,0h20L350,10z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqSection;
