import React from 'react'
import MainSlider from "../components/main/MainSlider";
import Brand from "../components/main/Brand";
import AboutArea from "../components/main/AboutArea";
import GalleryArea from '../components/main/GalleryArea';
import Token from "../components/main/Token";
import Hero from "../components/main/Hero";
import Ecosystem from "../components/main/Ecosystem";
import Why from "../components/main/Why";
// import Partners from "../components/main/Partners";
import Roadmap from "../components/main/Roadmap";
import TeamArea from "../components/main/TeamArea";
import PurchaseArea from "../components/main/PurchaseArea";
import Contact from "../components/main/Contact";
import FaqSection from "../components/faq/FaqSection";
import Staking from "../components/main/Staking";
import Parking from "../components/main/Parking";
import MembershipArea from '../components/main/Memberships';
function Home() {
  return (
	  <main>
	   <MainSlider/>
	   <Brand/>
	   <AboutArea/>
	   <GalleryArea/>
	   <Parking/>
	   <MembershipArea/>
	   <Staking />
	   <Token/>
	   <Hero/>
	   <Ecosystem/>
	   <Why/>
	   {/* <Partners/> */}
	   <Roadmap/>
	   <TeamArea/>
	
	   <PurchaseArea/>
	   <FaqSection/>
	   <Contact/>

	  </main>
	
  )
}

export default Home